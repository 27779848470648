import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/sign-in/:redirectPath?',
    name: 'sign-in',
    props: true,
    component: () =>
      import('@/views/PageSignIn')
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    props: true,
    component: () =>
      import('@/views/PageSignIn')
  },
  {
    path: '/user/:uid',
    name: 'user',
    props: true,
    component: () =>
      import('@/views/PageUser')
  },
  {
    path: '/create-post',
    name: 'create-post',
    props: true,
    component: () =>
      import('@/views/PageCreatePost')
  },
  {
    path: '/edit-profile',
    name: 'edit-profile',
    props: true,
    component: () =>
      import('@/views/PageEditProfile')
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    props: true,
    component: () =>
      import('@/views/PageTutorial')
  },
  {
    path: '/result/:field/:orderBy/:limit?/:score?',
    name: 'result',
    props: true,
    component: () =>
      import('@/views/PageResult')
  },
  {
    path: '/matching',
    name: 'matching',
    props: true,
    component: () =>
      import('@/views/PageMatching')
  },
  {
    path: '/:postId?',
    name: 'home',
    component: () =>
      import('@/views/PageHome')
  },
  // {
  //   path: '/tmp',
  //   name: 'tmp',
  //   props: true,
  //   component: () =>
  //     import('@/views/PageTmp')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
