<template lang="pug">
v-touch(v-on:panup="panup")
  transition(v-if="animation === 'slide-down'" name="slide-down")
    div(v-if="showMessage" @click="onMessage"
      ).navigation.wrap-navigation-message-slide-down.f.fc
      div.message-content.px16.py12.f.fm
        v-icon(v-if="icon" color="white" size="22px").mr12 {{icon}}
        span.fz14.line-clamp-1 {{message}}
        span(v-if="showOK"
          @click="showMessage = false").fz14.bold.ml8.cursor-pointer OK
  transition(v-else name="bottom-right")
    div(v-if="showMessage" @click="onMessage"
      ).navigation.wrap-navigation-message-bottom-right
      div.message-content.px16.py12.f.fm
        v-icon(v-if="icon" color="white" size="22px").mr4 {{icon}}
        span.fz14.line-clamp-1 {{message}}
        span(v-if="showOK"
          @click="onOK").fz14.bold.ml8.cursor-pointer OK
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
$message_height: 48px;
$top_margin: 8px;
$bottom_margin: 16px;
$right_margin: 16px;

.navigation {
  position: fixed;
  z-index: 10001;
  .message-content {
    max-width: $base_max_width_px;
    height: $message_height;
    background: $active_color;
    filter: $drop_shadow;
    border-radius: 9px;
    span {
      color: $dark_primary_text_color;
    }
  }
  &.wrap-navigation-message-slide-down {
    width: 100%;
    top: $top_margin;
    top: calc(#{$top_margin} + constant(safe-area-inset-top));
    top: calc(#{$top_margin} + env(safe-area-inset-top));
  }
  &.wrap-navigation-message-bottom-right {
    right: $right_margin;
    right: calc(#{$right_margin} + constant(safe-area-inset-right));
    right: calc(#{$right_margin} + env(safe-area-inset-right));
    bottom: $bottom_margin;
    bottom: calc(#{$bottom_margin} + constant(safe-area-inset-bottom));
    bottom: calc(#{$bottom_margin} + env(safe-area-inset-bottom));
  }
}

.slide-down-enter-active, .slide-down-leave-active {
  transition: all 0.6s $ease_out_expo;
  transform: translateY(0px);
}
.slide-down-enter, .slide-down-leave-to {
  transform: translateY(calc(-#{$message_height} - 32px));
  transform: translateY(calc(-#{$message_height} - 32px - constant(safe-area-inset-top)));
  transform: translateY(calc(-#{$message_height} - 32px - env(safe-area-inset-top)));
}
.slide-down-enter-to, .slide-down-leave {
  transform: translateY(0px);
}

.bottom-right-enter-active, .bottom-right-leave-active {
  transition: all 0.6s $ease_out_expo;
  transform: translateX(0px);
}
.bottom-right-enter, .bottom-right-leave-to {
  transform: translateX(calc(540px));
  transform: translateX(calc(540px + constant(safe-area-inset-right)));
  transform: translateX(calc(540px + env(safe-area-inset-right)));
}
.bottom-right-enter-to, .bottom-right-leave {
  transform: translateX(0px);
}
</style>

<script>
/*
usage:
this.$root.$emit('showSharedMessage', {
  message: 'メッセージ',
  icon: 'mdi-home'
})
*/
import Mixin from '@/components/mixin'

export default {
  mixins: [Mixin],
  data () {
    return {
      showMessage: false,
      showOK: false,
      action: null,
      animation: 'bottom-right',
      message: null,
      icon: null,
      to: null
    }
  },
  created () {
    // window.showSharedMessage = this.showSharedMessage
    this.$root.$on('showNavigationMessage', this.showSharedMessage)
  },
  methods: {
    async showSharedMessage (data) {
      this.showMessage = true
      this.message = data.message
      this.icon = data.icon ? data.icon : null
      this.to = data.to ? data.to : null
      this.showOK = data.showOK ? true : false
      this.action = data.action ? data.action : null
      this.animation = data.animation ? data.animation : 'bottom-right'
      await this.sleep(3600)
      this.showMessage = false
      this.to = ''
    },
    panup (e) {
      if (e.velocityY < 0) this.showMessage = false
    },
    onMessage () {
      if (this.to) {
        this.$router.push(this.to)
        this.showMessage = false
      }
    },
    onOK () {
      if (this.action) this.action()
      this.showMessage = false
    }
  }
}
</script>
