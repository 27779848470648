export default {
  computed: {
    isMobile () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (
        userAgent.includes('iphone') ||
        userAgent.includes('ipad') ||
        userAgent.includes('android') ||
        userAgent.includes('mobile')
      )
    },
    isiPad () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (
        userAgent.includes('ipad') ||
        userAgent.indexOf('ipad') > -1 ||
        (userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document)
      )
    },
    isSafari () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (userAgent.indexOf('safari') > -1) && (userAgent.indexOf('chrome') === -1)
    }
  },
  data () {
    return {
      pallete: {
        $active_color: '#1976d2', // '#1976d2',
        // $active_color: '#1eebd7', // '#1976d2',
        $deactive_color: '#757575',
        $primary_text_color: '#2a2a2a',
        $primary_bg_color: '#fff',
        $secondary_text_color: '#757575',
        $secondary_bg_color: '#f2f2f2',
        $secondary_active_color: '#757575',
        $dark_primary_bg_color: '#000',
        $overlay_color: 'rgba(0, 0, 0, 0.7)',
        $safe_color: '#1976d2',
        $green_color: '#1DC1AC',
        $favorite_color: '#FBC02D',
        $like_color: '#ff5252'
      }
    }
  },
  methods: {
    getIconByAssetType (type) {
      return this.assetTypes.filter(a => a.type === type)[0].icon
    },
    getNameByAssetType (type) {
      return this.assetTypes.filter(a => a.type === type)[0].name
    },
    // for image converter
    loadImage (src, crossOrigin) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        if (crossOrigin) image.crossOrigin = crossOrigin
        image.onload = () => resolve(image)
        image.onerror = (e) => reject(e)
        image.src = src
      })
    },
    getUniqueStr (myStrong) {
      let strong = 1000
      if (myStrong) strong = myStrong
      return new Date().getTime().toString(16)  + Math.floor(strong*Math.random()).toString(16)
    },
    // util
    sleep (msec) {
      return new Promise(resolve => setTimeout(resolve, msec))
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str;
      } else {
        return str.slice(0, num > 3 ? num - 3 : num) + '...';
      }
    },
    secondsToDate(secs) {
      const t = new Date(1970, 0, 1)
      t.setSeconds(secs)
      return t
    }
  }
}