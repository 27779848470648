import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#1DC1AC',
        secondary: '#1DC1AC',
        // accent: colors.red.darken3,
        // error: colors.red,
        // warning: colors.yellow,
        // info: colors.grey,
        // success: '#795548',
        // mycolor: '#e0e0e0'
      },
      dark: {
        primary: '#1DC1AC',
        secondary: '#1DC1AC'
      }
    },
  }
})
