// console.log('env:', process.env)

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID
}

const algoliaConfig = {
  appId: process.env.VUE_APP_ALGOLIA_APPID,
  // apiKey: process.env.VUE_APP_ALGOLIA_APIKEY,
  searchKey: process.env.VUE_APP_ALGOLIA_SEARCHKEY,
  index: {
    asset: process.env.VUE_APP_ALGOLIA_INDEX_ASSET,
    assetCreateAtDesc: process.env.VUE_APP_ALGOLIA_INDEX_ASSET_CREATE_DESC,
    assetCreateAtAndImportedNumDesc: process.env.VUE_APP_ALGOLIA_INDEX_ASSET_CREATE_AND_IMPORTED_NUM_DESC,
    comic: process.env.VUE_APP_ALGOLIA_INDEX_COMIC,
    post: process.env.VUE_APP_ALGOLIA_INDEX_POST,
    tag: process.env.VUE_APP_ALGOLIA_INDEX_TAG,
    user: process.env.VUE_APP_ALGOLIA_INDEX_USER,
  }
}

// const api = process.env.VUE_APP_API_LOCAL_ORIGIN
const api = process.env.VUE_APP_API_ORIGIN
const domain = process.env.VUE_APP_DOMAIN

// export default config
export { config, algoliaConfig, api, domain }
