<template lang="pug">
transition
  div(v-if="isShown" @click="cancel"
    class="dialog").wrap-shared-confirm.fixed.l0.t0.w-full.h-full.f.fh
    div.confirm-content.bg-primary.px20.pt18.pb10
      div.wrap-text.pb10
        span.fz16 {{ text }}
      div.f.fm.flex-right.pt10
        span(@click.stop="ok()").ok.fz16.mr12.cursor-pointer OK
        span(@click.stop="cancel()").cancel.fz16.cursor-pointer Cancel
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.wrap-shared-confirm {
  z-index: 10001;
  background: $overlay_color;
  .confirm-content {
    width: 320px;
    background: $primary_bg_color;
    filter: $drop_shadow;
    border-radius: 9px;
    pointer-events: all;
    .wrap-text {
      border-bottom: $basic_border;
    }
    .ok {
      color: $active_color;
    }
    .cancel {
      color: $dangerous_color;
    }
  }
}

.v-leave-active, .v-enter-active {
  transition: opacity 0.2s;
}
/* 表示アニメーション */
.v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave {
  opacity: 1;
}
</style>  

<script>
// example
// const go = await window.customConfirm('ユーザーをフォローするにはログインする必要があります')
export default {
  data () {
    return {
      isShown: false,
      text: '',
      resolve: () => {}
    }
  },
  mounted () {
    window.customConfirm = this.confirm
  },
  methods: {
    confirm (text) {
      this.text = text
      this.isShown = true
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    ok () {
      this.reset()
      this.resolve(true)
    },
    cancel () {
      this.reset()
      this.resolve(false)
    },
    reset () {
      this.isShown = false
      this.text = ''
    }
  }
}
</script>
