import db, { firebase } from '@/components/utils/firebase'

export const state = () => ({
  userDisplayName: null,
  userAuthEmail: null,
  isAuthenticating: true,
  isLoggedIn: false,
  isSigningOut: false,
  uid: null,
  subscriptionEnder: null,
  isAnonymous: null,
  underLoading: true
})

export const mutations = {
  updateUid (state, value) {
    state.uid = value
  },
  updateUserDisplayName (state, value) {
    state.userDisplayName = value
  },
  updateIsAuthenticating (state, value) {
    state.isAuthenticating = value
  },
  updateIsLoggedIn (state, value) {
    state.isLoggedIn = value
  },
  updateIsSigningOut (state, value) {
    state.isSigningOut = value
  },
  updateAuthStatus (state, user) {
    const isLoggedIn = !!user
    const hasAuthEmail = !!user && !!user.email
    state.userDisplayName = isLoggedIn ? user.displayName : null
    state.uid = isLoggedIn ? user.uid : null
    state.userAuthEmail = hasAuthEmail ? user.email : null
    state.isAuthenticating = false
    state.isLoggedIn = isLoggedIn
    state.isSigningOut = false
    state.isAnonymous = user ? user.isAnonymous : null
    state.underLoading = false
  },
  resetState (state) {
    state.isLoggedIn = false
    state.isAuthenticating = true
    state.userAuthEmail = null
    state.isSigningOut = false
    state.userDisplayName = null
    state.isAnonymous = null
  },
  updateSubscriptionEnder (state, value) {
    state.subscriptionEnder = value
  },
  updateUnderLoading (state, value) {
    state.underLoading = value
  }
}

export const actions = {
  setupAuthStateHandler ({ dispatch, commit, state }) {
    const shouldSetupAuthstate =
      !state.isLoggedIn && state.isAuthenticating && !state.isSigningOut
    if (shouldSetupAuthstate) {
      const ender = firebase.auth().onAuthStateChanged(user => {
        commit('updateAuthStatus', user)
      })

      commit('updateSubscriptionEnder', ender)
    }
  },
  endSubscription ({ state, commit }) {
    if (state.subscriptionEnder) {
      state.subscriptionEnder()
      commit('updateSubscriptionEnder', null)
    }
  },
  async signOut ({ commit, dispatch }) {
    commit('updateIsSigningOut', true)
    try {
      dispatch('endSubscription')
      await firebase.auth().signOut()
      commit('resetState')
    } catch (err) {
      console.error('error signing out of firebase', err)
    } finally {
      commit('updateIsSigningOut', false)
    }
    
    // if (Capacitor.isNative) {
    //   try {
    //     // FacebookLogin.logout()
    //   } catch (err) {
    //     // console.log('Facebook Logout Err:', err)
    //   }
    //   commit('resetState')
    // }
  },
  async afterSignIn ({ commit }, user) {
    const userDoc = await db.collection('USER').doc(user.uid)
      .get().then(d => { return d })
    if (!userDoc.exists) {
      // Sign up
      if (!user.photoURL) user.photoURL = 'https://firebasestorage.googleapis.com/v0/b/fir-tmp-project.appspot.com/o/public%2Faccount.png?alt=media&token=4ad2981f-61ac-42d9-a5ed-45eda74077d0'
      if (user.option) user = { ...user, ...user.option }
      const userObj = {
        uid: user.uid,
        name: user.displayName,
        photoURL: user.photoURL,
        createdAt: new Date()
      }
      await db.collection('USER').doc(user.uid).set(userObj)
    } else {
      // console.log('sign in')
    }
    commit('updateSubscriptionEnder', false)
  },
  setUnderLoading ({ commit }, underLoading) {
    commit('updateUnderLoading', underLoading)
  }
}
